import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import TeamuitjesSignup from './Pages/Teamuitjes-signup';
import AanpakkerVoorEenDagSignup from './Pages/AanpakkerVoorEenDag-signup';
import HeldenavondSignup from './Pages/Heldenavond-signup';
import { Link } from 'react-router-dom';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <div>
                    <div><Link to="aanvragen-teamuitjes">Teamuitje aanvragen</Link></div>
                    <div><Link to="aanvragen-aanpakker-voor-een-dag">Aanpakker voor een dag aanvragen</Link></div>
                    <div><Link to="aanvragen-heldenavond">Heldenavond aanvragen</Link></div>
                </div>,
            },
            {
                path: "aanvragen-teamuitjes",
                element: <TeamuitjesSignup page="teamuitjes" />,
            },
            {
                path: "aanvragen-aanpakker-voor-een-dag",
                element: <AanpakkerVoorEenDagSignup page="aanpakker" />,
            },
            {
                path: "aanvragen-heldenavond",
                element: <HeldenavondSignup page="heldenavond" />,
            }
        ]
    },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
        {/* <App /> */}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
