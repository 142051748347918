import { useState } from "react"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CompanyInformationFields(properties) {

    const {
        companyInformation = {},
        setCompanyInformation,
        number,
        invalid,
        page
    } = properties;

    const changeCompanyInformation = (e) => {
        setCompanyInformation({...companyInformation, [`${e.target.name}`]: e.target.value});
    }

    return (
        <div className="mx-2">

            <div className="lg:flex lg:flex-wrap lg:gap-x-8 lg:w-full">
                <p className="w-full font-proxima font-bold block mb-4">Bedrijfsgegevens</p>

                <div className="mb-6 lg:w-1/3">
                    <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        value={companyInformation.companyName}
                        placeholder="Bedrijfsnaam *"
                        onChange={e => changeCompanyInformation(e)}
                        required
                        className={`${invalid ? "peer/companyName invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}
                    />
                    <label htmlFor="companyName" className="font-proxima text-xs text-center sr-only">Bedrijfsnaam</label>
                    <span className="hidden peer-invalid/companyName:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen bedrijfsnaam ingevuld.</span>
                </div>

                <div className="mb-6 lg:w-1/3">
                    <input
                        type="text"
                        id="department"
                        name="department"
                        value={companyInformation.department}
                        placeholder="Afdeling"
                        onChange={e => changeCompanyInformation(e)}
                        className={`${invalid ? "peer/department invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}
                    />
                    <label htmlFor="department" className="font-proxima text-xs text-center sr-only">Afdeling</label>
                    <span className="hidden peer-invalid/department:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen afdeling ingevuld.</span>
                </div>

                <div className="mb-6 lg:w-1/3">
                    <input
                        type="text"
                        id="streetAndNumber"
                        name="streetAndNumber"
                        value={companyInformation.streetAndNumber}
                        placeholder="Straatnaam + huisnummer *"
                        onChange={e => changeCompanyInformation(e)}
                        required
                        className={`${invalid ? "peer/streetAndNumber invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}
                    />
                    <label htmlFor="streetAndNumber" className="font-proxima text-xs text-center sr-only">Straatnaam + huisnummer</label>
                    <span className="hidden peer-invalid/streetAndNumber:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen straatnaam + huisnummer ingevuld.</span>
                </div>

                <div className="mb-6 lg:w-1/3">
                    <input
                        type="text"
                        id="zipcode"
                        name="zipcode"
                        value={companyInformation.zipcode}
                        placeholder="Postcode *"
                        onChange={e => changeCompanyInformation(e)}
                        pattern="[1-9][0-9]{3}\s?[a-zA-Z]{2}"
                        required
                        className={`${invalid ? "peer/zipcode invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}
                    />
                    <label htmlFor="zipcode" className="font-proxima text-xs text-center sr-only">Postcode</label>
                    <span className="hidden peer-invalid/zipcode:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen postcode ingevuld.</span>
                </div>

                <div className="mb-6 lg:w-1/3">
                    <input
                        type="text"
                        id="city"
                        name="city"
                        value={companyInformation.city}
                        placeholder="Plaatsnaam *"
                        onChange={e => changeCompanyInformation(e)}
                        required
                        className={`${invalid ? "peer/city invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}
                    />
                    <label htmlFor="city" className="font-proxima text-xs text-center sr-only">Plaatsnaam</label>
                    <span className="hidden peer-invalid/city:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen plaatsnaam ingevuld.</span>
                </div>

            </div>

        </div>

    )



}
