import { DateTime } from "luxon";

export function getGregorianEasterDate(year) {
    const a = year % 19
    const b = Math.floor (year / 100)
    const c = year % 100
    const d = Math.floor (b / 4)
    const e = b % 4
    const g = Math.floor (((8 * b) + 13) / 25)
    const h = ((19 * a) + b - d - g + 15) % 30
    const i = Math.floor (c / 4)
    const k = c % 4
    const l = (32 + (2 * e) + (2 * i) - h - k) % 7
    const m = Math.floor ((a + (11 * h) + (19 * l)) / 433)
    const n = Math.floor ((h + l - (7 * m) + 90) / 25)
    const p = (h + l - (7 * m) + (33 * n) + 19) % 32
    return DateTime.fromObject ({
        day:    p,
        month:  n,
        year:   year
    })
}

export function isLiberationDay(date) {
    return date.month === 5 && date.day === 5;
}

export function isChristmasDay(date) {
    return date.month === 12 && (date.day === 25 || date.day === 26);
}

export function isNewsYearsDay(date) {
    return date.month === 1 && date.day === 1;
}

export function isWesternAscensionThursday(date) {
    const easternSunday = getGregorianEasterDate(date.year);
    const ascensionThursday = easternSunday.plus({ days: 39 });
    return ascensionThursday.equals(date);
}

export function isPenteCost(date) {
    const easternSunday = getGregorianEasterDate(date.year);
    const penteCost = easternSunday.plus({ days: 50 });
    return penteCost.equals(date);
}

export function isEasternMonday(date) {
    const easternSunday = getGregorianEasterDate(date.year);
    const easternMonday = easternSunday.plus({ days: 1 });
    return easternMonday.equals(date);
}

export function isEasternSunday(date) {
    const easternSunday = getGregorianEasterDate(date.year);
    return easternSunday.equals(date);
}

export function isEasternFriday(date) {
    const easternSunday = getGregorianEasterDate(date.year);
    const easternFriday = easternSunday.minus({ days: 2 });
    return easternFriday.equals(date);
}

export default function isHoliday(dateString) {
    const date = DateTime.fromFormat(dateString, 'dd-MM-yyyy');
    return isChristmasDay(date) || 
    isNewsYearsDay(date) || 
    isWesternAscensionThursday(date) || 
    isPenteCost(date) || 
    isEasternMonday(date) || 
    isEasternSunday(date) || 
    isEasternFriday(date) || 
    isLiberationDay(date);
}


export function formatDateFromISO(dateString) {
	const date = new Date(dateString);
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString();
	return `${day}-${month}-${year}`;
}

export function formatDate(dateString) {
	const date = new Date(dateString);
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString();
	return `${day}-${month}-${year}`;
  }
