import api from "../api/api";
// import { Server } from "../utils/config";
import { useEffect, useReducer } from "react";
import { Server } from "../Utils/config";


const AbstractListHook = (collectionName, stale, query = []) => {
    const reducer = (state, action) => {
        switch (action.type) {
            case FetchState.FETCH_INIT:
                return { ...state, isLoading: true, isError: false };
            case FetchState.FETCH_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    isError: false,
                    [collectionName + "List"]: action.payload,
                };
            case FetchState.FETCH_FAILURE:
                return { ...state, isLoading: false, isError: true };
            default:
                throw new Error();
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        isLoading: false,
        isError: false,
        [collectionName + "List"]: [],
    });

    useEffect(() => {
        let didCancel = false;
        const getList = async () => {
            dispatch({ type: FetchState.FETCH_INIT });
            try {
                const data = await api.listDocuments(Server.databaseID, Server.collections[collectionName], query);
                if (!didCancel) {
                    dispatch({ type: FetchState.FETCH_SUCCESS, payload: data.documents });
                }
            } catch (e) {
                if (!didCancel) {
                    dispatch({ type: FetchState.FETCH_FAILURE });
                }
            }
        };
        getList();
        return () => (didCancel = true);
    }, [stale]);

    // console.log("state", state);

    return [state];
}

export const useGetAanpakkerVoorEenDagRequestList = (stale, query) => {
    const [state] = AbstractListHook("AanpakkerVoorEenDagRequest", stale, query);
    state.AanpakkerVoorEenDagRequestList = state.AanpakkerVoorEenDagRequestList;
    return [state];
};

export const useGetMaatwerkRequestList = (stale, query) => {
    const [state] = AbstractListHook("CustomEvent", stale, query);
    state.CustomEventList = state.CustomEventList;
    return [state];
};

export const useGetUnavailableDateList = (stale, query) => {
    return AbstractListHook("UnavailableDate", stale, query);
};


export const useGetHeldenavondRequestList = (stale, query) => {
    const [state] = AbstractListHook("HeldenavondRequest", stale, query);
    state.HeldenavondRequestList = state.HeldenavondRequestList;
    return [state];
};

export const useGetTeamuitjeRequestList = (stale, query) => {
    const [state] = AbstractListHook("TeamuitjeRequest", stale, query);
    state.TeamuitjeRequestList = state.TeamuitjeRequestList;
    return [state];
};

export const useGetPublicSettings = () => {
    return AbstractListHook("PublicSettings", false);
}

export const FetchState = {
    FETCH_INIT: 0,
    FETCH_SUCCESS: 1,
    FETCH_FAILURE: 2,
};

export const useGetUser = () => {
    const reducer = (state, action) => {
        switch (action.type) {
            case FetchState.FETCH_INIT:
                return { ...state, isLoading: true, isError: false };
            case FetchState.FETCH_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    isError: false,
                    user: action.payload,
                };
            case FetchState.FETCH_FAILURE:
                return { ...state, isLoading: false, isError: true };
            default:
                throw new Error();
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        isLoading: false,
        isError: true,
        data: [],
    });

    useEffect(() => {
        let didCancel = false;
        const getTodos = async () => {
            dispatch({ type: FetchState.FETCH_INIT });
            try {
                const account = await api.getAccount();
                if (!didCancel) {
                    dispatch({ type: FetchState.FETCH_SUCCESS, payload: account });
                }
            } catch (e) {
                if (!didCancel) {
                    dispatch({ type: FetchState.FETCH_FAILURE });
                }
            }
        };
        getTodos();
        return () => (didCancel = true);
    }, []);

    return [state, dispatch];
};
