import { useCalendarEventContext } from "../Context/CalendarEventContext";
import { formatDate } from "../Utils/date";
import { useMemo } from "react";


export default function useAvailableCustomEvents() {
    const { customEvents } = useCalendarEventContext();

    const availableCustomEvents = useMemo(() => {

        if(customEvents) {
            let grouped = customEvents.filter(request => request.status !== 'rejected').reduce((col, item) => {
                return {...col, [formatDate(item.date)]: item };
            }, {})
            return grouped;
                
        }
        return {};

    }, [customEvents])

    return availableCustomEvents;
}