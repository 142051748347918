import {
    ChevronLeftIcon,
    ChevronRightIcon
} from '@heroicons/react/20/solid';
import { useState } from 'react';
import JobCorner from './job-corner.png'
import isHoliday from '../Utils/date';

/**
 * Number -> Number -> Page -> Array Result
 * Page = 'aanpakker' | 'heldenavond' | 'teamuitjes'
 * Result = {
 *  date        : Number
 *  dayOfWeek   : String
 *  fullDate    : String
 *  isToday     : Boolean
 *  month       : String
 *  timestamp   : Number
 *  unavailable : Boolean
 * }
 * @param year Number
 * @param month Number
 * @param page Page
 * @return Array Result
 */
function getWeekdays(year, month, page) {

    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const amsterdamTimezone = 'Europe/Amsterdam';

    const currentDate = new Date;
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const fromDate = new Date();

    if (page == "teamuitjes") {
        fromDate.setDate( fromDate.getDate() + 14 );
    } else if (page == "aanpakker") {
        fromDate.setDate( fromDate.getDate() + 7 );
    } else if (page == "heldenavond") {
        // fromDate.setDate( fromDate.getDate() );
    }

    fromDate.setTime( fromDate.getTime() - fromDate.getHours() * 3600 * 1000 - fromDate.getMinutes() * 60 * 1000 );

    const startDate = new Date(year, month - 1, 1);
    startDate.setDate(startDate.getDate() - ([0, 6].includes(startDate.getDay()) ? 0 : startDate.getDay() - 1)); // adjust to previous Monday
    let endDate = new Date(year, month, 0);
    endDate.setDate(endDate.getDate() + ([0, 6].includes(endDate.getDay()) ? 0 : 5 - endDate.getDay())); // adjust to next Friday

    let result = [];

    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {

        if (weekdays.includes(weekdays[d.getDay() - 1])) {
            result.push({
                month: d.toLocaleString("nl-NL", { timeZone: amsterdamTimezone, month: 'long' }),
                dayOfWeek: weekdays[d.getDay() - 1],
                timestamp: d.getTime(),
                date: d.getDate(),
                fullDate: `${String(d.getDate()).padStart(2, '0')}-${String(d.getMonth() + 1).padStart(2, '0')}-${d.getFullYear()}`,
                isToday: d.getFullYear() === currentYear && d.getMonth() === currentMonth && d.getDate() === currentDay,
                unavailable: d <= fromDate,
            });
        }
    }

    return result;
}


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * Properties -> React.Element
 * @param properties.calendarAvailableTill String
 * @param properties.dayCards Object (Object Any)
 * @param properties.NotAvailableYetDayCard React.Element
 * @param properties.onDayClick String -> Any
 * @param properties.page 'aanpakker' | 'heldenavond' | 'teamuitjes'
 * @param properties.passedDayCard React.Element
 * @return React.Element
 */
export default function Calendar({ dayCards, onDayClick, page, passedDayCard, calendarAvailableTill = "01-10-2024", NotAvailableYetDayCard }) {

    const currentDate = new Date;
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth + 1); // JavaScript months are 0-based
    
    const [tillDay, tillMonth, tillYear] = calendarAvailableTill.split('-');

    const tillTimestamp = (new Date(`${tillYear}-${tillMonth}-${tillDay}T00:00:00.000Z`)).getTime();

    const previousDisabled = selectedMonth == (currentMonth + 1) && selectedYear == currentYear;

    const selectPreviousMonth = (e) => {

        if(selectedMonth == (currentMonth + 1) && selectedYear == currentYear) {
            e.preventDefault();
            return;
        }


        if (selectedMonth === 1) {
            setSelectedYear(prevYear => prevYear - 1);
            setSelectedMonth(12);
        } else {
            setSelectedMonth(prevMonth => prevMonth - 1);
        }
        e.preventDefault();
    };

    const selectCurrentMonth = (e) => {
        setSelectedYear(currentYear);
        setSelectedMonth(currentMonth + 1);
        e.preventDefault();
    };

    const selectNextMonth = (e) => {

        // TODO: Dit is nu hardcoded er in gezet, later moeten we nog nadeken over hoe we dit in de toekomst het beste kunnen aanpakken
        if(selectedMonth >= tillMonth && selectedYear >= tillYear) {
            e.preventDefault();
            return;
            
        }

        if (selectedMonth === 12) {
            setSelectedYear(prevYear => prevYear + 1);
            setSelectedMonth(1);
        } else {
            setSelectedMonth(prevMonth => prevMonth + 1);
        }
        e.preventDefault();
    };

    const days = getWeekdays(selectedYear || currentYear, selectedMonth, page ).map(x => ({ ...x, events: [] }));
    const selectedDay = days.find((day) => day.isSelected)

    const monthName = (new Date(selectedYear, selectedMonth - 1, 1, 12)).toLocaleString('nl-NL', { timeZone: 'Europe/Amsterdam', month: 'long' });

    return <div>

        <div className="shadow w-screen lg:w-full lg:flex lg:flex-auto lg:flex-col gap-[2px] lg:rounded-full overflow lg:rounded-xl relative">
            <div>
                <div className="bg-cal-blue text-white text-xs font-semibold leading-6 flex p-3">

                    <div className="flex-auto">
                        <span className="font-proxima text-xl capitalize">{monthName} {selectedYear}</span>
                    </div>

                    <div className="flex flex-row justify-center">
                        <div>
                            { !previousDisabled && <button onClick={selectPreviousMonth}><ChevronLeftIcon className="w-6" /></button> }
                        </div>
                        <div>
                            <button onClick={selectCurrentMonth} className='font-proxima'>Vandaag</button>
                        </div>
                        <div>
                            <button onClick={selectNextMonth}><ChevronRightIcon className="w-6" /></button>
                        </div>
                    </div>


                </div>
                <div className="grid grid-cols-5 text-right text-xs font-semibold leading-6 text-white lg:flex-none">
                    <div className="bg-cal-blue p-2">
                        M<span className="sr-only sm:not-sr-only">a</span>
                    </div>
                    <div className="bg-cal-blue p-2">
                        D<span className="sr-only sm:not-sr-only">i</span>
                    </div>
                    <div className="bg-cal-blue p-2">
                        W<span className="sr-only sm:not-sr-only">o</span>
                    </div>
                    <div className="bg-cal-blue p-2">
                        D<span className="sr-only sm:not-sr-only">o</span>
                    </div>
                    <div className="bg-cal-blue p-2">
                        V<span className="sr-only sm:not-sr-only">r</span>
                    </div>
                </div>
            </div>
            <div className="flex bg-cal-light-gray text-xs leading-6 text-gray-700 lg:flex-auto">

                <img src={JobCorner} alt="Job Corner" className='absolute -left-[12.5rem] z-50 hidden lg:block' />

                <div className="w-full grid grid-cols-5 gap-[2px] ">
                    
                    {days.map((day) => {

                        const dayCard = dayCards[day.fullDate];
                       
                        let CardComponent = dayCard?.component;

                        let dayClasses = '';
                        let canClick = false;

                        if (
                            (page == 'heldenavond' && day.dayOfWeek != "Tuesday") ||
                            day.unavailable || isHoliday(day.fullDate)
                            ) {
                                if(passedDayCard || (page == 'heldenavond' && day.dayOfWeek != "Tuesday")) {

                                    CardComponent = passedDayCard;
                                    canClick = false;
                                } else {
                                    dayClasses = 'bg-cal-lighter-gray text-black';
                                }
                            
                        } else {
                            dayClasses = 'bg-white cursor-pointer'
                            canClick = true;
                        }

                        if ( typeof(dayCard) !== "undefined" && typeof(dayCard.clickable) !== "undefined") {
                            canClick = dayCard.clickable;

                            if (canClick == false) {
                                dayClasses = 'bg-white'
                            }

                        }

                        if(day.timestamp >= tillTimestamp && NotAvailableYetDayCard) {
                            dayClasses = 'bg-cal-lighter-gray text-black';
                            CardComponent = NotAvailableYetDayCard;
                            canClick = false;
                        }

                        return (
                            <div
                                {...(canClick && { onClick: () => onDayClick(day.fullDate) })}
                                key={day.fullDate}
                                className={classNames(
                                    dayClasses,
                                    'relative z-10 px-3 py-2 text-right lg:h-24 flex flex-col items-end aspect-square lg:aspect-auto'
                                )}
                            >
                                <time
                                    dateTime={day.fullDate}
                                    className={classNames(" relative z-30",
                                        dayCard ? dayCard.dayLabelClass : undefined,
                                        day.isToday
                                            ? 'flex h-6 w-6 items-center justify-center rounded-full bg-cal-blue font-semibold text-white'
                                            : undefined
                                    )}
                                >
                                    {day.date}
                                </time>
                                {CardComponent}
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>
    </div>

}

