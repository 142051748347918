export default function CalendarLegend() {
  return (
    <div className="flex flex-col lg:flex-row gap-2 lg:gap-5 px-2 py-4">
      <div className="flex flex-row gap-2">
        <div className="w-6 h-6 aspect-square border border-black "></div>
        <div className="font-proxima">Beschikbaar/beschikbare dagdelen</div>
      </div>
      <div className="flex flex-row gap-2">
        <div className="w-6 h-6 aspect-square border border-black bg-red-400"></div>
        <div className="font-proxima">Deels beschikbaar</div>
      </div>
      <div className="flex flex-row gap-2">
        <div className="w-6 h-6 aspect-square border border-black bg-cal-darker-gray"></div>
        <div className="font-proxima">Niet beschikbaar</div>
      </div>
    </div>
  )
}