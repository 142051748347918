import { useState } from "react"
import ReactDatepicker from "./Elements/Datepicker";
import { DateTime } from "luxon";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PersonInformationFields(properties) {

    const {
        visitorInformation = {},
        setVisitorInformation,
        number,
        index,
        page,
        invalid
    } = properties;

    const changeVisitorInformation = (e) => {

        setVisitorInformation({
            ...visitorInformation, [`${index}`]: {
                ...visitorInformation[`${index}`], [`${e.target.name}`]: e.target.value
            }
        });

    }

    function Prefix() {
        if (page != "aanpakker") {
            return null;
        }
        return <h3 className="font-proxima w-full font-bold mb-4">Gegevens persoon {number}</h3>
    }

    return (
        <div className="mx-2 lg:flex lg:flex-wrap lg:gap-x-8">
            <Prefix />
            {/* <h3 className="font-proxima text-xs w-full font-bold mb-4">Gegevens persoon {number}</h3> */}
            <div className="mb-6 lg:w-1/3">
                <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={visitorInformation[index].firstName}
                    placeholder="Voornaam *"
                    onChange={e => changeVisitorInformation(e)}
                    required
                    className={`${invalid ? "peer/firstName invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''}w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}
                />
                <label htmlFor="firstName" className="font-proxima text-xs text-center sr-only">Voornaam</label>
                <span className="hidden peer-invalid/firstName:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen voornaam ingevuld.</span>
            </div>

            <div className="mb-6 lg:w-1/3">
                <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={visitorInformation[index].lastName}
                    placeholder="Achternaam *"
                    onChange={e => changeVisitorInformation(e)}
                    required
                    // className="peer/lastName w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500"
                    className={`${invalid ? "peer/lastName invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}
                />
                <label htmlFor="lastName" className="font-proxima text-xs text-center sr-only">Achternaam</label>
                <span className="hidden peer-invalid/lastName:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen achternaam ingevuld.</span>
            </div>

            <div className="mb-6 lg:w-1/3">
                <input
                    type="email"
                    id="emailAddress"
                    name="emailAddress"
                    value={visitorInformation[index].emailAddress}
                    placeholder="E-mailadres *"
                    onChange={e => changeVisitorInformation(e)}
                    required
                    pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                    className={`${invalid ? "peer/emailAddress invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}
                />
                <label htmlFor="emailAddress" className="font-proxima text-xs text-center sr-only">E-mailadres</label>
                <span className="hidden peer-invalid/emailAddress:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen geldig e-mailadres ingevuld.</span>
            </div>

            <div className="mb-6 lg:w-1/3">
                <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={visitorInformation[index].phoneNumber}
                    placeholder="Telefoonnummer *"
                    onChange={e => changeVisitorInformation(e)}
                    required
                    className={`${invalid ? "peer/phoneNumber invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}
                />
                <label htmlFor="phoneNumber" className="font-proxima text-xs text-center sr-only">Telefoonnummer</label>
                <span className="hidden peer-invalid/phoneNumber:block w-full font-proxima text-red-500 rounded-xl mt-2">Er is geen Telefoonnummer ingevuld.</span>
            </div>

            {page !== "teamuitjes" &&
                <div className="mb-6 lg:w-1/3">
                    <ReactDatepicker
                        name="birthdate"
                        date={visitorInformation[index].birthdate}
                        classes={`${invalid ? "peer/birthdate invalid:border-red-400 invalid:bg-red-200 invalid:text-red-500" : ''} w-full rounded-xl border-2 border-grey px-4 py-2 font-proxima`}
                        setDate={(date) => {
                            setVisitorInformation({
                                ...visitorInformation, [`${index}`]: {
                                    ...visitorInformation[`${index}`], [`birthdate`]: date
                                }
                            });
                        }}
                    />
                    <label htmlFor="birthdate" className="font-proxima text-xs text-center sr-only">Geboortedatum</label>
                    <span className={`${invalid && visitorInformation[index].birthdate == "" ? "block" : "hidden"} w-full font-proxima text-red-500 rounded-xl mt-2`}>Er is geen geboortedatum ingevuld.</span>
                </div>
            }

        </div>

    )



}
