export const Server = {
    endpoint : process.env.REACT_APP_ENDPOINT || 'https://cloud.appwrite.io/v1',
    project: process.env.REACT_APP_PROJECT || '648c6fafddab8d5f54c0',
    collections : {
        'AanpakkerVoorEenDagRequest': '3755fa78aee8ecf74a9b037c69187f15d181',
        'HeldenavondRequest': '24b3458ee06237eba0be31308214d21cef07',
        'TeamuitjeRequest': 'ae820c72f5e86b1cf237861fa42a431b5ef2',
        'CustomEvent': '73be8f7864ba160c904a29ba3bcd7da85412',
        'UnavailableDate': '1bd29e9b192290c01ce873675d9ae9e08607',
        'PublicSettings': 'c0adbc52df587a66f18f6313dd484e122ff1'
    },
    databaseID : process.env.REACT_APP_DATABASE_ID || '648c7f1fe7cf8ca15b08',
    backendURL: process.env.API_URL || "https://jarige-job-teamuitjes-planner-api-development.srv02.ehero.es",

}

export const calendarLabels = {
	'aanpakker': 'Aanpakker voor een dag',
	'heldenavond': 'Heldenavond',
	'teamuitje': 'Teamuitje',
	'maatwerk': 'Maatwerk event'
}

export const statusClassName = {
	'confirmed': 'bg-green-500',
	'pending': 'bg-orange-500',
	'rejected': 'bg-red-500',
}

export const notifactionLabels = {
	'selectedDay': 'Er is bij stap 1 geen datum geselecteerd.',
	'partOfday': 'Er is bij stap 2 geen dagdeel geselecteerd.',
	'formfields': 'Niet alle verplichte gegevens van stap 3 zijn ingevuld.',
    'underagedParticipants': 'Er zijn minderjarige deelnemers geselecteerd.',
    'privacyAgreed': 'Er is geen akkoord gegeven voor de privacyverklaring.',
}
