import { Client as Appwrite, Databases, Account, Query } from "appwrite";
import { Server } from "../Utils/config";

let api = {
    sdk: null,

    provider: () => {
        if (api.sdk) {
            return api.sdk;
        }
        let appwrite = new Appwrite();
        appwrite.setEndpoint(Server.endpoint).setProject(Server.project);
        const account = new Account(appwrite);
        const database = new Databases(appwrite);

        api.sdk = { database, account };
        return api.sdk;
    },

    createAccount: (email, password, name) => {
        return api.provider().account.create("unique()", email, password, name);
    },

    getAccount: () => {
        let account = api.provider().account;
        return account.get();
    },

    createSession: (email, password) => {
        return api.provider().account.createEmailSession(email, password);
    },

    deleteCurrentSession: () => {
        return api.provider().account.deleteSession("current");
    },

    createDocument: (databaseId, collectionId, data, permissions) => {
        return api
            .provider()
            .database.createDocument(databaseId, collectionId, 'unique()', data, permissions);
    },

    listDocuments: async (databaseId, collectionId, query = []) => {
        let documents = [];
        let results = await api.provider().database.listDocuments(databaseId, collectionId, [...(query || [])]);
        documents.push(...results.documents);
        let total = results.total;
        let stopAfter = 0;
        while(results.total > documents.length && stopAfter < 25) {
            results = await api.provider().database.listDocuments(databaseId, collectionId, [Query.limit(25), Query.offset(documents.length), ...(query || [])]);
            documents.push(...results.documents);
            stopAfter++;
        }
        return { documents };
    },

    updateDocument: (databaseId, collectionId, documentId, data) => {
        return api
            .provider()
            .database.updateDocument(databaseId, collectionId, documentId, data);
    },

    deleteDocument: (databaseId, collectionId, documentId) => {
        return api.provider().database.deleteDocument(databaseId, collectionId, documentId);
    },
};

export default api;