import Logo from './logo.svg';

export default function Header() {

  return <header className="bg-cal-blue mb-10s py-4">
    
    <div className="flex items-center justify-start lg:w-[90vw] mx-auto px-4">
      <div className='flex items-center h-12 w-16 mr-9'>
        <img src={Logo} alt="Jarige Job" />
      </div>

      <p className="text-sm text-white">Ieder kind verdient een verjaardag</p>
    </div>
    
  </header>
  
}
