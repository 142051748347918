export default function SelectDate(properties) {

    const { page } = properties;

    const fromText = {
        "teamuitjes": "( minimaal 2 weken van te voren )",
        "aanpakker": "( minimaal 1 week van te voren )",
        "heldenavond": "",
    }

    return (
        <div className="lg:w-[70vw] mx-auto px-2">
            <h2 className="font-proxima font-black text-xl">1. Selecteer jouw gewenste datum {fromText[page]}</h2>
        </div>
    )
}