export default function LoadingOverlay({ loading }) {

    if (!loading) return;

    return <div className="absolute w-full h-full top-0 left-0 z-50 flex flex-col gap-4 justify-center items-center bg-white/70">
            
        <svg className="animate-spin -ml-1 mr-3 h-16 w-16 text-cal-blue" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <div className="text-2xl px-4 text-center">
            Bezig met laden van beschikbaarheidsgegevens...
        </div>
        
    </div>
}