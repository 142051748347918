import { useState } from "react"
import Counter from "./Forms/Controls/Counter"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * Properties -> React.Element
 * @param properties.selectedDate Void | String
 * @param properties.setVisitorCount Integer -> Any
 * @param properties.setVisitorInformation Object (Object Any) -> Any
 * @param properties.visitorCount Integer
 * @param properties.visitorInformation Object (Object Any)
 * @param properties.visitorLimit Integer
 * @return React.Element
 */
export default function NumberOfPeople(properties) {

    const {
        selectedDate,
        visitorCount,
        setVisitorCount,
        visitorInformation,
        setVisitorInformation,
        visitorLimit
    } = properties;

    const increaseVisitorCount = e => {

        if (visitorCount < visitorLimit) {

            setVisitorCount(visitorCount + 1);

            setVisitorInformation({
                ...visitorInformation, [`${visitorCount}`]: {
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    phoneNumber: '',
                    birthdate: '',
                }
            });
        }
        
    }

    const decreaseVisitorCount = e => {

        if (visitorCount > 0) {

            setVisitorCount(visitorCount - 1);

            setVisitorInformation(current => {
                const copy = {...current};
                delete copy[visitorCount - 1 ];
                return copy;
            });

        }
    }

    return (
        <div className="bg-[#FAFAFA] mx-2 mt-7 lg:mb-14">
            <h2 className="font-proxima font-black text-xl mb-2">2. Aantal personen</h2>

            <div className="shadow rounded-xl border-1 border-grey p-5 font-proxima bg-[#FFFFFF]">

                <div>
                    <label htmlFor="numberOfPeople" className="w-full font-proxima font-bold block">Met hoeveel personen kom je aanpakken?*</label>
                    <span className="font-light mb-2 lg:mb-4 leading-tight block">Minimale leeftijd is 18+.</span>
                    <Counter
                        count={visitorCount}
                        countUp={increaseVisitorCount}
                        countDown={decreaseVisitorCount}
                        countMax={visitorLimit}
                        disabled={selectedDate == null}
                    />
                </div>

            </div>
        </div>
    )
}
