import { notifactionLabels } from "../../Utils/config"

export default function DisplayNotifications(properties) {

    const { notifications = [] } = properties;

    if (notifications.length === 0) return;

    return (
        <div className="w-full mt-6">
            <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                <p className="font-bold">Oops</p>
                {notifications.map((notification, index) => {
                    return <p key={index}>{notifactionLabels[notification]}</p>
                })}
            </div>
        </div>
    )
}