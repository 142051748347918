import { Button, Tooltip } from 'flowbite-react';

export default function TooltipContainer(properties) {

    const { text = "Extra optie voor lunch (optioneel): De lunch kun je zelf bestellen bij één van onze samenwerkende cateraars en is voor eigen rekening." } = properties;

    return (
        <Tooltip content={text}>
            <button type="button" className="ml-2 flex h-5 w-5 items-center justify-center rounded-full bg-cal-blue italic text-white text-sm">i</button>
            <div id="tooltip-default" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                {text}
            </div>
        </Tooltip>
    )
}