
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

/**
 * Properties -> React.Element
 * @param properties.count Void | Integer
 * @param properties.countDown React.MouseEventHandler React.Element
 * @param properties.countMax Void | Integer
 * @param properties.countMin Void | Integer
 * @param properties.countUp React.MouseEventHandler React.Element
 * @param properties.disabled Void | Boolean
 * @return React.Element
 */
export default function Counter(properties) {

    const {
        count = 1,
        countUp,
        countDown,
        countMax = 10,
        countMin = 1,
        disabled = false
    } = properties;

    return (
        <div className="shadow rounded-xl border-1 border-grey px-4 py-2 flex gap-6 w-fit">
            <div 
                className={classNames(
                    count <= countMin ? 'text-[#8D8D8D]' : '',
                    'inline',
                    disabled
                    ? 'cursor-default pointer-events-none'
                    : 'cursor-pointer'
                )}
                onClick={count > countMin && ! disabled
                    ? countDown
                    : Function.prototype}
            >-</div>
            <p>{count}</p>
            <div 
                 className={classNames(
                    count >= countMax ? 'text-[#8D8D8D]' : '',
                    'inline',
                    disabled
                    ? 'cursor-default pointer-events-none'
                    : 'cursor-pointer'
                )}
                onClick={count < countMax && ! disabled
                    ? countUp
                    : Function.prototype}
            >+</div>
        </div>
    )
}
