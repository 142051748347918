import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
    useGetPublicSettings

} from "../Hooks";

  
const PublicSettingsContext = createContext();

// Create a provider component
export default function PublicSettingsProvider({ children }) {

    const [stale, setStale] = useState({ stale: false });

    const [{ PublicSettingsList, isLoading, isError }] = useGetPublicSettings(stale);

    const loading = isLoading;
    const [publicSettings, setPublicSettings] = useState({});

    const reload = () => {
        setStale(x => !x);
    }

    useEffect(() => {
        let _publicSettings = {};
        PublicSettingsList.forEach((item) => {
            _publicSettings = {..._publicSettings, [item.key]: item.value};
        });
        setPublicSettings(_publicSettings);
    }, [
        PublicSettingsList
    ]);


    return (
        <PublicSettingsContext.Provider value={{ publicSettings, reload }}>
            {children}
        </PublicSettingsContext.Provider>
    );
};

export const usePublicSettingsContext = () => {
    return useContext(PublicSettingsContext);
};

