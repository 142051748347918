
import Header from "./Components/Header";
import { Outlet, NavLink } from "react-router-dom";
import CalendarEventProvider from "./Context/CalendarEventContext";
import PublicSettingsProvider from "./Context/PublicSettingsContext";

function App() {

    return (
        <CalendarEventProvider>
            <PublicSettingsProvider>
                <div className="bg-[#FAFAFA] h-full">
                    <Header />
                    {/* <ClientCalendar /> */}
                    <Outlet />
                </div>
            </PublicSettingsProvider>
        </CalendarEventProvider>
    );
}

export default App;
